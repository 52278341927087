/* src/admin/AdminPanel.css */
.admin-panel {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.admin-panel h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.admin-panel input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.admin-panel button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.admin-panel button:hover {
    background-color: #0056b3;
}

.admin-panel .image-field {
    display: flex;
    align-items: center;
}

.admin-panel .image-field input {
    flex: 1;
}

.admin-panel .image-field button {
    width: auto;
    margin-left: 10px;
}
